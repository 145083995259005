import { ConversationHistoryModel, TwilioModel } from './state';
import { createAction } from '@ngrx/store';
import { props } from '@ngrx/store';
import { AccountUnderlyingModel } from 'src/app/model/account-underlying.model';

export const ToggleChatAction = createAction('[Chat view] toggle chat');
export const ChatLoadedAction = createAction('[Chat view] chat loaded');

export const RequestChatClosedAction = createAction('[Chat view] chat closed');
export const ResponseChatClosedAction = createAction('[Chat view] set chat closed', props<{ payload: TwilioModel }>());

export const RequestNewChatAction = createAction('[Chat view] chat new');
export const ResponseNewChatAction = createAction('[Chat view] set chat new', props<{ payload: TwilioModel }>());

export const RequestAction = createAction('[Chat view] get data');
export const ResponseAction = createAction('[Chat view] set data', props<{ payload: TwilioModel }>());

export const AddMessageAction = createAction('[Chat view] add message', props<{ payload: ConversationHistoryModel }>());

