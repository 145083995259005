import { AppFileModel } from "@ifaa-components/ui-components";
import { FormGroupState } from "ngrx-forms";
import { BankDetailsModel } from '../../../../model/bank-details.model';

export interface ILodgeClaimState {
  form: FormGroupState<LodgeClaimModel>;
  currentStep: number;
  totalSteps: number;
  validated: boolean;
  lastModifiedTime: Date;
}

export class LodgeClaimSubmissionModel{
  employeeId: number = 0;
  taxFileNumber: string = '';
  hasTFN: boolean = false;
  amountType: number = 0;
  claimType: number = 0;
  bankDetails: BankDetailsModel = new BankDetailsModel();
  employers: CirtEmployerModel[] = [new CirtEmployerModel()];
  waitForFinalContributions: boolean = true;
  workingHolidayMakerVisa: boolean = false;
  attachments: AppFileModel[] = [];
}

export class LodgeClaimModel {
  employeeId: number = 0;
  taxFileNumber: string = '';
  hasTFN: boolean = false;
  amountType: number = 0;
  claimType: number = 0;
  bankDetails: BankDetailsModel = new BankDetailsModel();
  employers: CirtEmployerModel[] = [new CirtEmployerModel()];
  waitForFinalContributions: boolean = true;
  workingHolidayMakerVisa: boolean = false;
  documents: CirtDocumentModel[] = [
    {
      title: "Certified Identification",
      description:'Please provide certified proof of your identity as it is required to allow us to process your claim.',
      mandatory: true,
      attachments: [new AppFileModel()]
    },
    {
      title: "Bank Statement",
      description:"Copy of bank statement (or other acceptable evidence of your bank account details) for verification purposes. This should be a recent copy (no older than 6 months) and must clearly display the bank's logo, member's full name, address and account details.",
      mandatory: true,
      attachments: [new AppFileModel()]
    },
    {
      title: "Employment Separation Certificate",
      description:"Document that is provided by your employer upon request when you have ceased work and certifies required employment information.",
      mandatory: false,
      attachments: [new AppFileModel()]
    }
  ]
}

export class CirtEmployerModel {
  employmentId: number = 0;
  employerId: number = 0;
  startDate: string = '';
  amount: number = 0;
  claimAmount: boolean = false;
}

export class MemberTfnResultModel {
  hasTfn: boolean = false;
}

export class CirtDocumentModel {
  title: string = '';
  description:string='';
  mandatory: boolean = true;
  attachments: AppFileModel[] = [new AppFileModel()];
}