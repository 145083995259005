import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureKey } from '../../store/cirt.reducers';
import { CIRTState } from '../../store/cirt.states';
import { ILodgeClaimState } from './state';

export const memberPortalState = createFeatureSelector<CIRTState>(featureKey);

export const lodgeClaim = createSelector(memberPortalState, (state) => state.lodgeClaim);

export const lodgeClaim_Form = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form
);

export const lodgeClaim_CurrentStep = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.currentStep
);

export const lodgeClaim_TotalSteps = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.totalSteps
);

export const lodgeClaim_Validated = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.validated
);

export const lodgeClaim_EmployerList = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form.controls.employers.controls
);

export const lodgeClaim_LastModifiedTime = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.lastModifiedTime
);

export const lodgeClaim_Documents = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => state.form.controls.documents
)

export const lodgeClaim_DocumentControls = createSelector(
  lodgeClaim,
  (state: ILodgeClaimState) => {
    var controls = [];

    controls = state.form.controls.documents.controls.map(control => {
      return {
        id:control.id,
        values: control.value
      }
    })

    return controls;
  }
)