import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { ComponentBase } from 'src/app/views/component-base';
import { AppState } from 'src/app/store/app.states';
import { accessYourSuperStart_Filter, accessYourSuperStart_Model } from './selectors';
import { memberAccountDropdown_SelectedAccount } from '../member-account-dropdown/selectors';
import { RequestAction } from './actions';
import { AccessYourSuperApplicationsModel } from 'src/app/model/access-your-super-applications.model';
import { combineLatest } from 'rxjs';
import { distinct } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';

@Component({
  selector: 'app-access-your-super-start',
  templateUrl: './access-your-super-start.component.html',
  styleUrls: ['./access-your-super-start.component.scss'],
  host: {
    class: 'w-100'
  }
})
export class AccessYourSuperStartComponent extends ComponentBase implements OnInit, OnDestroy {

  model$ = this.store.pipe(select(accessYourSuperStart_Model));
  filter$ = this.store.pipe(select(accessYourSuperStart_Filter));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  
  currentList: AccessYourSuperApplicationsModel[] = [];

  constructor(
    public store: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.screenWidthChange(this.store, x => { });

    // send api request when we have the selected account
    this.sub = this.selectedAccount$.pipe(distinct(x => {
      if (x){
        return x.accountId
      }
    })).subscribe(x => {
      var accountId = x?.accountId;
      
      // Check if request has been sent so its not sent multiple times
      if (accountId) {
        this.dispatch(this.store, RequestAction({ accountId: accountId }));
      }
    });

    this.sub = combineLatest([this.filter$, this.model$])
      .pipe(
        map(([filter, model]) =>({filter, model})))
        .subscribe(x=>{ 
          if(x.model && x.filter)
          {
            this.currentList = x.model[x.filter.value.activeOptionButton]
          }
        }
      );
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  createNewApplication(){
    
  }

  continueApplication(applicationId:number){
    
  }

  cancelApplication(applicationId:number){
    
  }
}