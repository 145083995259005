import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { BaseService } from './base.services';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ChatService extends BaseService {

  constructor(private http: HttpClient,
    public toastr: ToastrService,
    private store: Store<any>) {
    super(toastr);
  }

  details(): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/chat/details`, { headers: this.headers() });
  }

  closeChat(): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/chat/closeChat`, { headers: this.headers() });
  }

  newChat(): Observable<any> {
    return this.http.get(environment.apiUrl + `/member-portal/chat/newChat`, { headers: this.headers() });
  }


}
