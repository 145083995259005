import { UIComponentState } from '@ifaa-components/ui-components/lib/store/app.states';
import { ComponentBase } from 'src/app/views/component-base';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { CIRTState } from '../../store/cirt.states';
import { LodgeClaimModel, LodgeClaimSubmissionModel } from './state';
import { EmployerListRequestAction, MemberDataRequestAction, NextStepAction, PreviousStepAction, RemoveFileAttachmentAction, ResetFormAction, SubmitRequestAction, ValidateFormAction } from './actions';
import { lodgeClaim_CurrentStep, lodgeClaim_DocumentControls, lodgeClaim_Documents, lodgeClaim_EmployerList, lodgeClaim_Form, lodgeClaim_LastModifiedTime, lodgeClaim_TotalSteps, lodgeClaim_Validated } from './selectors';
import { memberAccountDropdown_SelectedAccount } from 'src/app/modules/shared/components/member-account-dropdown/selectors';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { SetErrorsAction } from 'ngrx-forms';
import { ValidationErrors } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-tenant-lodge-claim',
  templateUrl: './lodge-claim.component.html',
  styleUrls: ['./lodge-claim.component.scss'],
  host: {
    class: 'w-100'
  }
})

export class TenantLodgeClaimComponent extends ComponentBase implements OnInit, OnDestroy {

  form$ = this.cirtstore.pipe(select(lodgeClaim_Form));
  // claimDocumentationList$ = this.cirtstore.pipe(select(lodgeClaim_claimDocumentationList));
  employerList$ = this.cirtstore.pipe(select(lodgeClaim_EmployerList));
  currentStep$ = this.cirtstore.pipe(select(lodgeClaim_CurrentStep));
  totalSteps$ = this.cirtstore.pipe(select(lodgeClaim_TotalSteps));
  validated$ = this.store.pipe(select(lodgeClaim_Validated));
  selectedAccount$ = this.store.pipe(select(memberAccountDropdown_SelectedAccount));
  lastModified$ = this.store.pipe(select(lodgeClaim_LastModifiedTime));

  documents$ = this.store.pipe(select(lodgeClaim_Documents));
  documentControls$ = this.store.pipe(select(lodgeClaim_DocumentControls));

  accountId = 0;
  @ViewChild('myTemplate') customTemplate: TemplateRef<any>;
  extensions: string[] = ['.pdf', '.png', '.jpg'];
  maxfilesize = 5242880;//5mb
  constructor(public store: Store<UIComponentState>,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    public cirtstore: Store<CIRTState>,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.store.dispatch(ResetFormAction());

    this.sub = this.selectedAccount$.subscribe(x => {
      if (x && this.accountId === 0) {
        this.accountId = x.accountId;
        this.store.dispatch(MemberDataRequestAction({ accountId: x.accountId }));
        this.dispatch(this.store, EmployerListRequestAction({ accountId: x.accountId }));
      }
    });
    this.sub = this.validated$.subscribe(validated => {
      if (!validated)
        this.dispatch(this.store, ValidateFormAction());
    });

    this.sub = this.lastModified$.subscribe(x => {
      if (x) {
        this.bottomSheet.open(this.customTemplate, {
          disableClose: true
        });

        this.dispatch(this.store, ResetFormAction());
      }
    });

    this.sub = this.documentControls$.pipe(distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y))).subscribe(x => {
      if (x) {
        x.forEach(doc => {
          if (doc.values.mandatory && !doc.values.attachments.find(x => x.base64?.length > 0)) {
            const errors = { 'required': 'This is required' } as ValidationErrors;
            this.dispatch(this.store, new SetErrorsAction(doc.id, errors));
          }
          
          if (doc.values.mandatory && doc.values.attachments.find(x => x.base64?.length > 0)) {
            const errors = { } as ValidationErrors;
            this.dispatch(this.store, new SetErrorsAction(doc.id, errors));
          }

          if (!doc.values.mandatory){
            const errors = { } as ValidationErrors;
            this.dispatch(this.store, new SetErrorsAction(doc.id, errors));
          }
        })
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }

  onSubmitClick(data: LodgeClaimModel) {
    const attachments = data.documents
    .reduce((acc, doc) => acc.concat(doc.attachments), [])
    .filter(attachment => attachment.base64.length > 0);

    const payload = {
      ...data,
      attachments
    } as LodgeClaimSubmissionModel;

    this.dispatch(this.store, SubmitRequestAction({ accountId: this.accountId, payload }));
  }

  onRemoveAttachmentClick(index: number) {
    // this.dispatch(this.store, RemoveAttachmentByIndexAction({ index }));
  }

  onPreviousClick() {
    this.dispatch(this.store, PreviousStepAction());
  }

  onNextClick() {
    this.dispatch(this.store, NextStepAction());
  }

  onGoToDashboardClick() {
    this.bottomSheet.dismiss();
    this.router.navigate(['/']);
  }

  removeDocument(overviewIndex: number, docIndex: number) {
    this.store.dispatch(RemoveFileAttachmentAction({ docOverviewIndex: overviewIndex, docIndex }))
  }
}


