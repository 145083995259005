import { getMemberView_Model } from './selectors';
import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OnDestroy } from '@angular/core';
import { RequestAction } from './actions';
import { ComponentBase } from '../../../../views/component-base';
import { AppState } from '../../../../store/app.states';
import { MemberPortalSharedState } from '../../store/shared.states';
import { commomState_LoggedinInfo, commomState_CommunicationPreferenceList } from 'src/app/store/common/common.selectors';
import { AddressModel, Helper } from '@ifaa-components/ui-components';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { withLatestFrom } from 'rxjs/internal/operators/withLatestFrom';



@Component({
  selector: 'app-member-view',
  templateUrl: './member-view.component.html',
  styleUrls: ['./member-view.component.scss']
})
export class MemberViewComponent extends ComponentBase implements OnInit, OnDestroy {

  data$ = this.store.pipe(select(getMemberView_Model));
  @Input() itemclass: string = 'w-50';
  loggedIn$ = this.appstore.pipe(select(commomState_LoggedinInfo));
  helper = new Helper();
  communicationOption$ = this.appstore.pipe(select(commomState_CommunicationPreferenceList));
  communicationDescription = '';
  constructor(public store: Store<MemberPortalSharedState>,
    public appstore: Store<AppState>) {
    super();
  }

  ngOnInit() {
    super.ngOnInitBase();
    this.dispatch(this.store, RequestAction());
  }

  ngOnDestroy() {
    super.ngOnDestroyBase();
  }
}
