<div id="chat-view-container">

  <div #chat [hidden]="!(isChatOpened$ | async)" class="animate__animated">
    <div class="chat card">
      <div class="chat-title p-2 d-flex align-items-center d-flex flex-row">
        <mat-icon class="mr-1">question_answer</mat-icon>

        Live chat
      </div>
      <div class="messages p-2 " #messages>
        <!-- <div *ngIf="(model$ | async)?.conversationHistory?.length == 0"
          class="no-messages d-flex align-items-center flex-column">
          <mat-icon>support_agent</mat-icon>
          <label>How can I help you today?</label>
        </div> -->
        <ul class="timeline" *ngIf="(model$ | async)?.conversationHistory?.length ==0">

          <li class="timeline-inverted">
            <div class="timeline-badge warning ">
              <mat-icon>support_agent</mat-icon>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4 class="timeline-title">Agent</h4>
                <label class="date">{{newDate | date:'dd/MM/YYYY hh:mm a'}}</label>
              </div>
              <div class="timeline-body">
                <p>How can I help you today?</p>
              </div>

            </div>
          </li>
        </ul>
        <ul class="timeline" *ngIf="(model$ | async)?.conversationHistory?.length >0">

          <li class="timeline-inverted" *ngFor="let item of (model$ | async)?.conversationHistory">
            <div class="timeline-badge  {{item.isMemberMessage?'success':'warning'}}  ">
              <mat-icon *ngIf="!item.isMemberMessage">support_agent</mat-icon>
              <mat-icon *ngIf="item.isMemberMessage">person</mat-icon>
            </div>
            <div class="timeline-panel pb-2">
              <div class="timeline-heading">
                <h4 class="timeline-title d-flex align-items-center">{{item.author}}
                  <mat-icon class="verified" *ngIf="!item.isMemberMessage">verified</mat-icon>
                </h4>
                <label class="date">{{item.date | date:'dd/MM/YYYY hh:mm a' }}</label>
              </div>
              <div class="timeline-body">
                <p [innerHTML]="item.body"></p>
              </div>

            </div>
          </li>

          <li class="timeline-inverted" *ngIf="conversationStatus != 'active'">
            <div class="timeline-badge  end  ">
              <mat-icon>question_answer</mat-icon>
            </div>
            <div class="timeline-panel pb-0">
              <div class="timeline-heading">
                <h4 class="timeline-title chat-closed">Chat closed</h4>
                <label class="date"></label>
              </div>
            </div>
          </li>

          <li class="timeline-inverted" *ngIf="this.isWaitingAgent">
            <div class="timeline-badge  end  ">
              <mat-icon class="animate__animated animate__swing animate__slower animate__infinite">hourglass_top</mat-icon>
            </div>
            <div class="timeline-panel pb-0">
              <div class="timeline-heading">
                <h4 class="timeline-title waiting-agent">Waiting for an Agent to join...</h4>
                <label class="date"></label>
              </div>
            </div>
          </li>

        </ul>
      </div>
      <div class="typing d-flex flex-row align-items-center" *ngIf="isTyping">
        <mat-icon class="animate__animated animate__flash animate__slower animate__infinite">more_horiz</mat-icon>
        Agent is typing
      </div>
      <div class="action d-flex flex-column p-2" *ngIf="conversationStatus == 'active'">
        <form [ngrxFormState]="form" (ngSubmit)="onSubmitClick(form.value)" *ngIf="form$ | async; let form">
          <app-entry-textarea title="Enter your message..." [control]="form.controls.message">
          </app-entry-textarea>

          <app-button loading class="" title="Send message" icon="send" type="submit"
            (onClick)="onSubmitClick(form.value)" [isDisabled]="!form.value.message">
          </app-button>
        </form>

      </div>
      <div class="action d-flex flex-column p-2" *ngIf="conversationStatus != 'active'">
        <app-button loading class="" title="Start new chat" icon="add_comment" type="submit"
          (onClick)="onStartNewChatClick()">
        </app-button>
      </div>
    </div>
  </div>
  <button mat-mini-fab color="primary" [matBadgeHidden]="unreadMessages == 0" matBadge="{{unreadMessages}}" matBadgeColor="warn" class="entry-button" (click)="onEntryButtonClick()">
    <mat-icon *ngIf="!(isChatOpened$ | async)">question_answer</mat-icon>
    <mat-icon *ngIf="isChatOpened$ | async">expand_more</mat-icon>
  </button>

</div>
